<template>
  <main class="sub __agree-wrap">
    <div class="inner __join-wrap">
      <div class="tit req-type">
        <h1>이메일주소 무단수집거부</h1>
      </div>
      <div class="sub-cont">
        <div class="terms_wrap">
          <strong>제 1 조 (목적)</strong> <br /><br />

          1. 개인정보란 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는
          성명, 주민등록번호 등의 사항에 의하여 당해 개인을 식별할 수 있는
          정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와
          용이하게 결합하여 식별할 수 있는 것을 포함합니다.)를 말합니다.<br />
          2. ㈜탑스맨(이하 "회사"라 함)은 귀하의 개인정보보호를 매우 중요시하며,
          『개인정보보호법』상의 개인정보보호규정을 준수하고 있습니다. 회사는
          개인정보처리방침을 통하여 귀하께서 제공하시는 개인정보가 어떠한 용도와
          방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고
          있는지 알려드립니다.<br />
          3. 개인정보처리방침은 정부의 법령이나 지침의 변경, 또는 보다 나은
          서비스의 제공을 위하여 그 내용이 변경될 수 있습니다. 이 경우 회사는 웹
          사이트에서 공지사항에 이를 올리거나 이메일을 통해서 공지하고
          있습니다.<br />
          4. 회사는 개인정보처리방침을 사이트 첫 화면 또는 첫 화면과의
          연결화면을 통해 공개함으로써 이용자가 언제나 용이하게 보실 수 있도록
          조치하고 있습니다.<br />
          5. 회사는 개인정보처리방침의 지속적인 개선을 위하여 개인정보처리방침을
          개정하는데 필요한 절차를 정하고 있습니다.<br /><br /><br />

          <strong>제 2 조 (개인정보 수집에 대한 동의 및 수집 방법)</strong
          ><br /><br />

          1. 회사는 이용자께서 회사의 동의문 또는 이용약관의 내용에 대해
          ‘동의함’ 또는 ‘동의하지 않음’을 클릭할 수 있는 절차를 마련하여
          ‘동의함’을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다. 단,
          회사는 다음 각 호의 어느 하나에 해당하는 경우에는 동의 없이 이용자의
          개인정보를 수집∙이용할 수 있습니다.<br />
          1) 정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한
          개인정보로서 경제적∙기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게
          곤란한 경우<br />
          2) 정보통신서비스 제공에 따른 요금정산을 위하여 필요한 경우<br />
          3) 그 밖에 법률에 특별한 규정이 있는 경우<br />
          4) 계약을 이행하기 위하여 필요한 개인정보로서 경제적ㆍ기술적인 사유로
          통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우<br />
          - 디바이스 ID, 서비스이용기록, IP 주소, 접속로그, 단말기 OS와 버전,
          단말기 모델명, 브라우저 버전<br />
          5) 수집 목적과 합리적인 관련성이 있고 정보주체에게 불이익이 발생하지
          않으며 안전성 확보 조치를 적용한 경우<br />
          - 당초 수집 목적과 관련성<br />
          - 수집 정황 또는 처리 관행에 비춰봤을때 개인정보의 추가적인 이용에
          대한 예측 가능성<br />
          - 정보주체의 이익 침해 여부<br />
          - 가명처리 또는 암호화 등 안전성 확보에 필요한 조치 적용 등의 사항을
          모두 고려하여 판단<br /><br />

          2. 회사가 개인정보를 수집하는 경우에는 반드시 사전에 이용자에게 해당
          사실을 알리고 동의를 구하고 있으며, 아래와 같은 방법을 통해 개인정보를
          수집합니다. 1) 홈페이지 회원가입 및 서비스 이용 과정에서 이용자가
          개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우 2) 제휴
          서비스 또는 단체 등으로부터 개인정보를 제공받는 경우 3) 고객센터를
          통한 상담 과정에서 웹페이지, 앱, 메일, 팩스, 전화 등을 통하는 경우 4)
          온∙오프라인에서 진행되는 이벤트∙행사 등을 통한 경우
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {}
</script>

<style></style>
